.more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 36px;
    width: 89vw;
    margin-top: 50px;
    margin-bottom: 80px;
    background-color: #F9F9F9;
    border: none;
}
.more-button:hover {
cursor: pointer;
}