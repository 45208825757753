.aboutme__container-description-name {
    margin: 0;
    padding: 0;
    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
}

@media (max-width: 768px) {
    .aboutme__container-description-name {
        font-size: 30px;
        font-weight: 400;
        line-height: 58px;
    }
}

@media (max-width: 500px) {
    .aboutme__container-description-name {
        margin-top: 47px;
    }
}