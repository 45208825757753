.aboutme__container-description-story {
  margin: 0;
  padding: 0;
  margin-top: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

@media (max-width: 768px) {
  .aboutme__container-description-story {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin-right: 36px;
  }
}

@media (max-width: 969px) {
  .aboutme__container-description-story {
    margin-right: 36px;
  }
}