.about__container-name {
    font-size: 22px;
    font-weight: 400;
    line-height: 26.63px;
    color: #000000;
    margin: 0;
    margin-bottom: 23px;
    margin-top: 110px;
}

@media (max-width: 500px) {
    .about__container-name {
        font-size: 18px;
        font-weight: 400;
        line-height: 21.78px;
        margin-top: 70px;
    }
}