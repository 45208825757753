.photo-grid__name {
    font-family: "Inter", "Arial", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15.73px;
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    margin: 0;
    max-width: 70%;
}