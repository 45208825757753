@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@import url(./blocks/header/header__home.css);
@import url(./blocks/header/header.css);
@import url(./blocks/header/header-color.css);
@import url(./blocks/header/__logo/header__logo.css);
@import url(./blocks/header/__links/header__links.css);
@import url(./blocks/header/__links/header__links-signup.css);
@import url(./blocks/header/__links/header__links-login.css);

@import url(./blocks/header/__movies/header__movies.css);
@import url(./blocks/header/__links/header__links-account.css);
@import url(./blocks/header/__links/header__links-accountpic.css);
@import url(./blocks/header/__links/header__links-accountpicaround.css);
@import url(./blocks/header/__links/header__links-movies.css);
@import url(./blocks/header/__links/header__links-saved.css);

@import url(./blocks/header/__hamburger/header__hamburger.css);
@import url(./blocks/header/__hamburger/header__hamburger_menu.css);
@import url(./blocks/header/__hamburger/header__hamburger_menu-list.css);
@import url(./blocks/header/__hamburger/header__hamburger_menu-listitem.css);
@import url(./blocks/header/__hamburger/header__hamburger_button.css);
@import url(./blocks/header/__hamburger/header__hamburger_menu-cross.css);
@import url(./blocks/header/__hamburger/header__hamburger_shade.css);
@import url(./blocks/header/__hamburger/header__hamburger_link.css);
@import url(./blocks/header/__hamburger/header__hamburger_menu-cross_image.css);

@import url(./blocks/promo/promo.css);
@import url(./blocks/promo/__logo/promo__logo.css);
@import url(./blocks/promo/__text/promo__text.css);

@import url(./blocks/navigation/navigation.css);
@import url(./blocks/navigation/__link/navigation__link.css);
@import url(./blocks/navigation/__links/navigation__links.css);

@import url(./blocks/footer/footer.css);
@import url(./blocks/footer/__container/footer__container.css);
@import url(./blocks/footer/__container/footer__container-copyright.css);
@import url(./blocks/footer/__container/footer__container-copyright-line.css);
@import url(./blocks/footer/__container/footer__container-copyright-bottom.css);
@import url(./blocks/footer/__container/footer__container-links.css);
@import url(./blocks/footer/__container/footer__container-links-yandex.css);
@import url(./blocks/footer/__container/footer__container-links-github.css);

@import url(./blocks/about/about.css);
@import url(./blocks/about/__container/about__container.css);
@import url(./blocks/about/__container/about__container-name.css);
@import url(./blocks/about/__container/about__container-line.css);
@import url(./blocks/about/__container/about__container-description.css);
@import url(./blocks/about/__container/about__container-description-top.css);
@import url(./blocks/about/__container/about__container-description-bottom.css);
@import url(./blocks/about/__container/about__container-weekline-one.css);
@import url(./blocks/about/__container/about__container-weekline-second.css);
@import url(./blocks/about/__container/about__container-weekline.css);
@import url(./blocks/about/__container/about__container-weekline-sign.css);

@import url(./blocks/techs/techs.css);
@import url(./blocks/techs/__container/techs__container.css);
@import url(./blocks/techs/__container/techs__container-name.css);
@import url(./blocks/techs/__container/techs__container-line.css);
@import url(./blocks/techs/__container/techs__container-head.css);
@import url(./blocks/techs/__container/techs__container-text.css);
@import url(./blocks/techs/__container/techs__container-grid.css);
@import url(./blocks/techs/__container/techs__container-grid-element.css);
@import url(./blocks/techs/__container/techs__container-grid-wrap.css);

@import url(./blocks/aboutme/aboutme.css);
@import url(./blocks/aboutme/__container/aboutme__container.css);
@import url(./blocks/aboutme/__container/aboutme__container-name.css);
@import url(./blocks/aboutme/__container/aboutme__container-line.css);
@import url(./blocks/aboutme/__container/aboutme__container-description-avatar.css);
@import url(./blocks/aboutme/__container/aboutme__container-description.css);
@import url(./blocks/aboutme/__container/aboutme__container-description-column.css);
@import url(./blocks/aboutme/__container/aboutme__container-description-name.css);
@import url(./blocks/aboutme/__container/aboutme__container-description-profession.css);
@import url(./blocks/aboutme/__container/aboutme__container-description-story.css);
@import url(./blocks/aboutme/__container/aboutme__container-description-github.css);

@import url(./blocks/portfolio/portfolio.css);
@import url(./blocks/portfolio/__container/portfolio__container.css);
@import url(./blocks/portfolio/__container/portfolio__container-header.css);
@import url(./blocks/portfolio/__container/portfolio__container-line.css);
@import url(./blocks/portfolio/__container/_site/portfolio__container-site.css);
@import url(./blocks/portfolio/__container/portfolio__container-arrow.css);
@import url(./blocks/portfolio/__container/_site/portfolio__container-site-arrow.css);
@import url(./blocks/portfolio/__container/portfolio__container-list.css);

@import url(./blocks/signup/signup.css);
@import url(./blocks/signup/__container/signup__container.css);
@import url(./blocks/signup/__container/signup__container-header.css);
@import url(./blocks/signup/__container/signup__container-logo.css);
@import url(./blocks/signup/__container/signup__container-input.css);
@import url(./blocks/signup/__container/signup__container-input-name.css);
@import url(./blocks/signup/__container/signup__container-button.css);
@import url(./blocks/signup/__bottom/signup__bottom.css);
@import url(./blocks/signup/__bottom/signup__bottom-text.css);
@import url(./blocks/signup/__bottom/signup__bottom-link.css);
@import url(./blocks/signup/__container/signup__container-wrong.css);
@import url(./blocks/signup/__container/signup__container-form.css);
@import url(./blocks/signup/__container/signup__container-button-signin.css);
@import url(./blocks/signup/__container/signup__container-wrong-visible.css);
@import url(./blocks/signup/__container/signup__container-button-disable.css);

@import url(./blocks/searchform/searchform.css);
@import url(./blocks/searchform/__container/searchform__container.css);
@import url(./blocks/searchform/__container/searchform__container-bar.css);
@import url(./blocks/searchform/__container/searchbar__container-bar-input.css);
@import url(./blocks/searchform/__container/searchform__container-bar-button.css);
@import url(./blocks/searchform/__container/searchform__container-bar-pic.css);
@import url(./blocks/searchform/__container/searchform__container-tumbler.css);
@import url(./blocks/searchform/__container/searchform__container-short-sign.css);
@import url(./blocks/searchform/__container/searchform__container-short.css);
@import url(./blocks/searchform/__container/searchform__container-button.css);

@import url(./blocks/photo-grid/photo_grid.css);
@import url(./blocks/photo-grid/__item/photo-grid__item.css);
@import url(./blocks/photo-grid/__likeme/photo-grid__likeme.css);
@import url(./blocks/photo-grid/__bottom/photo-grid__bottom.css);
@import url(./blocks/photo-grid/__name/photo-grid__name.css);
@import url(./blocks/photo-grid/__likeme/photo-grid__likeme-button.css);
@import url(./blocks/photo-grid/__likeme/photo-grid__likeme-heart.css);
@import url(./blocks/photo-grid/__bottom/photo-grid__bottom-time.css);

@import url(./blocks/more/more.css);
@import url(./blocks/more/more-text.css);
@import url(./blocks/more/more-button.css);

@import url(./blocks/profile/profile.css);
@import url(./blocks/profile/profile__header.css);
@import url(./blocks/profile/profile__data/profile__data.css);
@import url(./blocks/profile/profile__bottom/profile__bottom.css);
@import url(./blocks/profile/profile__data/profile__data-name.css);
@import url(./blocks/profile/profile__bottom/profile__bottom-text.css);
@import url(./blocks/profile/profile__bottom/profile__bottom-link.css);
@import url(./blocks/profile/profile__container.css);
@import url(./blocks/profile/profile__bottom/profile__bottom-text-disabled.css);
@import url(./blocks/profile/profile-updated.css);
@import url(./blocks/profile/profile-somethingwrong.css);

@import url(./blocks/notfound/notfound.css);
@import url(./blocks/notfound/notfound_digit.css);
@import url(./blocks/notfound/notfound_sign.css);
@import url(./blocks/notfound/notfound_link.css);

@import url(./blocks/preloader/preloader.css);
@import url(./blocks/preloader/__container/preloader__container.css);
@import url(./blocks/preloader/__round/preloader__round.css);

@import url(./blocks/movies/movies-message.css);



* {
    font-family: 'Inter', sans-serif;
}