.about__container-description-top {
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    margin: 0;
    margin-bottom: 26px;
    margin-top: 70px;
}

@media (max-width: 500px) {
    .about__container-description-top {
        margin-top: 60px;
    }
}