.aboutme__container-description-column {
    display: flex;
    flex-direction: column;
    width: 47vw;
}

@media (max-width: 500px) {
    .aboutme__container-description-column {
        width: 95vw;
    }
}