.searchform__container-bar-input {
    border-radius: 50px;
    display: flex;
    width: 100%;
    border: 0;
    outline: none;
    position: relative;
    left: 20px;
}

.searchform__container-bar-input::-webkit-search-cancel-button {
    display: none;
  }
  
  .searchform__container-bar-input::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
  }