.signup__container-button-signin {
    width: 32vw;
    height: 45px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    text-align: center;
    color: #FFFFFF;
    background-color: #3456F3;
    margin: 0;
    padding: 0;
    margin-top: 179px;
    border: 0;
    border-radius: 3px;
  }
  
  @media (max-width: 900px) {
    .signup__container-button-signin {
      width: 51vw;
      margin-top: 192px;
    }
  }
  
  @media (max-width: 500px) {
    .signup__container-button-signin {
      width: 81vw;
      margin-top: 267px;
    }
  }
  
  .signup__container-button-signin:hover {
    opacity: 80%;
    cursor: pointer;
  }