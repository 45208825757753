.promo__text {
  margin: 0;
  color: white;
  font-size: 50px;
  font-weight: 400;
  line-height: 58px;
}

@media (max-width: 910px) {
  .promo__text {
    margin: 0;
    padding: 0;
    padding-right: 20px;
  }
}

@media (max-width: 768px) {
  .promo__text {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 41px;
  }
}

@media (max-width: 611px) {
  .promo__text {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 36px;
  }
}

@media (max-width: 500px) {
  .promo__text {
    margin: 0;
    padding: 0;
    text-align: center;
    width: 94vw;
    font-size: 31px;
    line-height: 39px;
  }
}

@media (max-width: 320px) {
  .promo__text {
    margin: 0;
    padding: 0;
    text-align: center;
    width: 94vw;
    font-size: 31px;
    line-height: 39px;
  }
}