.about__container-weekline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 110px;
}

@media (max-width: 768px) {
    .about__container-weekline {
        margin-bottom: 100px;
    }
}

@media (max-width: 500px) {
    .about__container-weekline {
        margin-bottom: 69px;
    }
}