.promo {
  display: flex;
  margin: 0 auto;
  background-color: #073042;
  justify-content: space-around;
  height: 522px;
  padding-left: 70px;
  padding-right: 70px;
  align-items: center;
}

@media (max-width: 768px) {
  .promo {
    flex-direction: column-reverse;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .promo {
    height: 570px;
    padding-bottom: 141px;
  }
}

@media (max-width: 320px) {
  .promo {
    height: 570px;
    padding-bottom: 103px;
  }
}