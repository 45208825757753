.photo-grid__item {
  min-width: 284px;
  width: 100%;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  object-fit: cover;
  height: 70%;
}

@media (max-width: 1024px) {
  .photo-grid__item {
    min-width: 300px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .photo-grid__item {
    min-width: 352px;
    width: 100%;
  }
}

@media (max-width: 425px) {
  .photo-grid__item {
    min-width: 288px;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .photo-grid__item {
    min-width: 288px;
    width: 100%;

  }
}