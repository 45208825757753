.footer__container-copyright-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    line-height: 18.15px;
}

@media (max-width: 500px) {
    .footer__container-copyright-bottom {
        flex-direction: column-reverse;
        margin-top: 28px;
        font-size: 12px;
        line-height: 14.52px;
    }
}