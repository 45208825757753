.about__container-weekline-one {
    display: flex;
    width: 20vw;
    background-color: #2BE080;
    height: 36px;
    margin: 0;
    margin-top: 110px;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
}