.about__container-weekline-second {
    display: flex;
    width: 70vw;
    background-color: #F2F2F2;
    height: 36px;
    margin: 0;
    margin-top: 110px;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
}