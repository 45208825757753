.portfolio__container-header {
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: 400;
    line-height: 20px;
    color: #A0A0A0;
    margin-top: 91px;
}

@media (max-width: 500px) {
    .portfolio__container-header {
        margin-top: 5px;
    }
}