.photo-grid {
  margin: 0px;
  display: grid;
  padding: 92px 70px 0px;
  grid-template-columns: repeat(auto-fill, minmax(284px, 1fr));
  list-style-type: none;
  grid-gap: 20px 20px;
}

@media (max-width: 1024px) {
  .photo-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 14px 14px;
    padding: 92px 48px 0px;
  }
}

@media (max-width: 768px) {
  .photo-grid {
    grid-template-columns: repeat(auto-fill, minmax(352px, 1fr));
    grid-gap: 16px 16px;
    padding: 88px 24px 0px;
  }
}

@media (max-width: 425px) {
  .photo-grid {
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    grid-gap: 16px 16px;
    padding: 88px 16px 0px;
  }
}

@media (max-width: 320px) {
  .photo-grid {
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    grid-gap: 12px 12px;
    padding: 64px 16px 0px;
  }
}