.notfound_digit {
    font-size: 140px;
    font-weight: 400;
    line-height: 169.43px;
    margin: 0;
    padding: 0;
    margin-top: 246px;
}

@media (max-width: 768px) {
    .notfound_digit {
        margin-top: 408px;
    }
}

@media (max-width: 500px) {
    .notfound_digit {
        font-size: 80px;
        font-weight: 400;
        line-height: 96.82px;
        margin-top: 329px;
    }
}