.techs__container-head {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
    margin-top: 90px;
}

@media (max-width: 500px) {
    .techs__container-head {
        font-size: 30px;
    }
}