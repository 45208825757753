.header__hamburger {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 44px;
  height: 44px;
  background: white;
  border: none;
}

@media (min-width: 769px) {
  .header__hamburger {
    display: none;
  }
}