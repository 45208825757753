.signup__bottom-link {
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 400;
    color: #3456F3;
    text-decoration: none;
}

@media (max-width: 769px) {
    .signup__bottom-link {
        font-size: 12px;
        line-height: 14.52px;
    }
}