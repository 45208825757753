.header__logo {
  width: 38px;
  height: 38px;
  margin-top: 18px;
  margin-bottom: 18px;

}

@media (max-width: 768px) {
  .header__logo {
    margin: 0;
    padding: 0;
  }
}