.header__hamburger_button {
    width: 100px;
    height: 32px;
    background-color: #F5F5F5;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
    padding: 0;
    border: none;
}