.aboutme__container-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    margin-top: 66px;
}

@media (max-width: 500px) {
    .aboutme__container-description {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}