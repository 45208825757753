.preloader__round {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: #FF6838;
    border-radius: 50px;
}

.preloader__round::after,
.preloader__round::before {
    content: '';
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
}

.preloader__round::after {
    background: white;
    animation: preloader-inside-white 1s ease-in-out infinite;
}

.preloader__round::before {
    z-index: 10;
    background: #FF6838;
    animation: preloader-inside-red 1s ease-in-out infinite;
}