.techs__container-text {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 26px;
}

@media (max-width: 500px) {
    .techs__container_grid {
        font-size: 12px;
        line-height: 14.52px;
    }
}