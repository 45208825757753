.portfolio__container-arrow {
    height: 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}
.portfolio__container-arrow:hover {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }