.aboutme__container-description-avatar {
    height: 327px;
    border-radius: 20px;
    width: 22vw;
    height: fit-content;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.18);
}

@media (max-width: 768px) {
    .aboutme__container-description-avatar {
        width: 36vw;
    }
}

@media (max-width: 500px) {
    .aboutme__container-description-avatar {
        width: 95vw;
    }
}