.profile__data {
    /* width: 410px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 11px;
    font-weight: 500;
    line-height: 13.31px;
    margin: 0;
    padding: 0;
}