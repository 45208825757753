.header__links-movies {
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    padding: 0;
    margin-right: 81px;
}

.header__links-movies:hover {
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}