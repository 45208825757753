.aboutme__container-description-github {
    margin: 0;
    padding: 0;
    margin-top: 11px;
    font-size: 18px;
    font-weight: 500;
    line-height: 16.94px;
    text-decoration: none;
    color: black;
}

@media (max-width: 768px) {
    .aboutme__container-description-github {
        margin-top: 8px;
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .aboutme__container-description-github {
        margin-top: 0px;
        font-size: 10px;
    }
}