.footer__container-links-yandex {
    margin-right: 20px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    line-height: 15.73px;
    color: black;
}

@media (max-width: 500px) {
    .footer__container-links-yandex {
        margin-right: 0px;
    }
}