.profile__bottom-text {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 15.73px;
  margin-bottom: 16px;
  border: none;
  background: none;
}

.profile__bottom-text:hover {
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}