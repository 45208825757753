.notfound_sign {
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-top: 5px;
}

@media (max-width: 500px) {
    .notfound_sign {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        margin-top: 10px;
    }
}