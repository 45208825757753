.searchform__container {
  width: 89vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #E8E8E8;
  margin-bottom: 10px;
}

@media (max-width: 1210px) {
  .searchform__container {
    flex-direction: column;
    margin-top: 60px;
  }
}