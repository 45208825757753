.techs__container-grid {
    margin: 0;
    padding: 0;
    margin-top: 120px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 100px;
    width: 89vw;

}

@media (max-width: 775px) {
    .techs__container-grid {
        justify-content: flex-start;
    }
}